import Vue from 'vue'

// wraps root Vue components to pass css class
// and to not reload full page with HMR (note: HMR not working though :()
export default Vue.extend({
  name: 'Proxy',
  functional: true,
  props: {
    component: {
      type: Object,
      required: true
    },
    props: {
      type: Object,
      required: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  render(createElement) {
    return createElement(this.component, {
      class: this.props.class,
      props: this.props
    })
  }
})

<script>
export default {
  render(h) {
    return h(
      'div',
      { class: 'sk-circle' },
      // eslint-disable-next-line prefer-spread
      Array.apply(null, { length: 12 }).map(() => h('div', { class: 'sk-child' }))
    )
  }
}
</script>
